import _thunk from "./lib/thunk.js";
var exports = {};
var createThunk = _thunk;

function Procedure() {
  this.argTypes = [];
  this.shimArgs = [];
  this.arrayArgs = [];
  this.arrayBlockIndices = [];
  this.scalarArgs = [];
  this.offsetArgs = [];
  this.offsetArgIndex = [];
  this.indexArgs = [];
  this.shapeArgs = [];
  this.funcName = "";
  this.pre = null;
  this.body = null;
  this.post = null;
  this.debug = false;
}

function compileCwise(user_args) {
  //Create procedure
  var proc = new Procedure(); //Parse blocks

  proc.pre = user_args.pre;
  proc.body = user_args.body;
  proc.post = user_args.post; //Parse arguments

  var proc_args = user_args.args.slice(0);
  proc.argTypes = proc_args;

  for (var i = 0; i < proc_args.length; ++i) {
    var arg_type = proc_args[i];

    if (arg_type === "array" || typeof arg_type === "object" && arg_type.blockIndices) {
      proc.argTypes[i] = "array";
      proc.arrayArgs.push(i);
      proc.arrayBlockIndices.push(arg_type.blockIndices ? arg_type.blockIndices : 0);
      proc.shimArgs.push("array" + i);

      if (i < proc.pre.args.length && proc.pre.args[i].count > 0) {
        throw new Error("cwise: pre() block may not reference array args");
      }

      if (i < proc.post.args.length && proc.post.args[i].count > 0) {
        throw new Error("cwise: post() block may not reference array args");
      }
    } else if (arg_type === "scalar") {
      proc.scalarArgs.push(i);
      proc.shimArgs.push("scalar" + i);
    } else if (arg_type === "index") {
      proc.indexArgs.push(i);

      if (i < proc.pre.args.length && proc.pre.args[i].count > 0) {
        throw new Error("cwise: pre() block may not reference array index");
      }

      if (i < proc.body.args.length && proc.body.args[i].lvalue) {
        throw new Error("cwise: body() block may not write to array index");
      }

      if (i < proc.post.args.length && proc.post.args[i].count > 0) {
        throw new Error("cwise: post() block may not reference array index");
      }
    } else if (arg_type === "shape") {
      proc.shapeArgs.push(i);

      if (i < proc.pre.args.length && proc.pre.args[i].lvalue) {
        throw new Error("cwise: pre() block may not write to array shape");
      }

      if (i < proc.body.args.length && proc.body.args[i].lvalue) {
        throw new Error("cwise: body() block may not write to array shape");
      }

      if (i < proc.post.args.length && proc.post.args[i].lvalue) {
        throw new Error("cwise: post() block may not write to array shape");
      }
    } else if (typeof arg_type === "object" && arg_type.offset) {
      proc.argTypes[i] = "offset";
      proc.offsetArgs.push({
        array: arg_type.array,
        offset: arg_type.offset
      });
      proc.offsetArgIndex.push(i);
    } else {
      throw new Error("cwise: Unknown argument type " + proc_args[i]);
    }
  } //Make sure at least one array argument was specified


  if (proc.arrayArgs.length <= 0) {
    throw new Error("cwise: No array arguments specified");
  } //Make sure arguments are correct


  if (proc.pre.args.length > proc_args.length) {
    throw new Error("cwise: Too many arguments in pre() block");
  }

  if (proc.body.args.length > proc_args.length) {
    throw new Error("cwise: Too many arguments in body() block");
  }

  if (proc.post.args.length > proc_args.length) {
    throw new Error("cwise: Too many arguments in post() block");
  } //Check debug flag


  proc.debug = !!user_args.printCode || !!user_args.debug; //Retrieve name

  proc.funcName = user_args.funcName || "cwise"; //Read in block size

  proc.blockSize = user_args.blockSize || 64;
  return createThunk(proc);
}

exports = compileCwise;
export default exports;